exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cy-404-js": () => import("./../../../src/pages/cy/404.js" /* webpackChunkName: "component---src-pages-cy-404-js" */),
  "component---src-pages-cy-cookies-js": () => import("./../../../src/pages/cy/cookies.js" /* webpackChunkName: "component---src-pages-cy-cookies-js" */),
  "component---src-pages-cy-index-js": () => import("./../../../src/pages/cy/index.js" /* webpackChunkName: "component---src-pages-cy-index-js" */),
  "component---src-pages-cy-privacy-notice-js": () => import("./../../../src/pages/cy/privacy-notice.js" /* webpackChunkName: "component---src-pages-cy-privacy-notice-js" */),
  "component---src-pages-cy-vocabulary-js": () => import("./../../../src/pages/cy/vocabulary.js" /* webpackChunkName: "component---src-pages-cy-vocabulary-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-cookies-js": () => import("./../../../src/pages/en/cookies.js" /* webpackChunkName: "component---src-pages-en-cookies-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-notice-js": () => import("./../../../src/pages/en/privacy-notice.js" /* webpackChunkName: "component---src-pages-en-privacy-notice-js" */),
  "component---src-pages-en-vocabulary-js": () => import("./../../../src/pages/en/vocabulary.js" /* webpackChunkName: "component---src-pages-en-vocabulary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-handbook-js": () => import("./../../../src/templates/handbook.js" /* webpackChunkName: "component---src-templates-handbook-js" */),
  "component---src-templates-lesson-js": () => import("./../../../src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---src-templates-resources-js": () => import("./../../../src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */)
}

